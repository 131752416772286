import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";

import useApi from "../../hooks/useApi";

const ModalUseTerm = ({ show, onHide }) => {
    const { fetchApi } = useApi();
    const cotacao = useSelector(state => state.cotacao);
    const [contract, setContract] = useState('');

    useEffect(() => {
        (async () => {
            if (cotacao) {
                let formData = {
                    Pets: cotacao.pets,
                    DadosClientes: {
                        IndividuosNome: cotacao.Nome,
                        IndividuosDocumento: cotacao.Documento,
                        IndividuosEnderecosCep: cotacao.Cep,
                        IndividuosEnderecosLogradouro: cotacao.Logradouro,
                        IndividuosEnderecosNumero: cotacao.Numero,
                        IndividuosEnderecosComplemento: cotacao.Complemento,
                        IndividuosEnderecosBairro: cotacao.Bairro,
                        IndividuosEnderecosCidadesNome: cotacao.CidadesNome,
                        IndividuosEnderecosEstadosNome: cotacao.EstadosNome
                    }
                };

                const { response, data } = await fetchApi(`Contratos/PegarContratoPublico`, 'POST', formData);
                if (response.ok) setContract(data.Contrato);
            }
        })()
    }, []);

    return (
        <Modal size="lg" show={show} onHide={onHide} centered className="modal-use-term">
            <Modal.Header closeButton>
                <Modal.Title>Contrato</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="contract" dangerouslySetInnerHTML={{ __html: contract }}></div>

                <div className="row justify-content-center">
                    <div className="col-12 col-sm-6">
                        <div className="d-grid">
                            <button className="btn btn-primary btn-rounded" onClick={onHide}>
                                <FaTimes /> Fechar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalUseTerm;
