import { useEffect, useState } from 'react';
import store from '../../redux';

import { Link, useNavigate } from 'react-router-dom';
import { IoIosCard, IoIosBarcode, IoMdArrowBack, IoIosCheckmark, IoIosInformationCircle } from "react-icons/io";
import { useSelector } from 'react-redux';
import { createSlug, formatMoney, getCookie } from '../../utils/functions';
import { toast } from 'react-toastify';
import { api } from '../../utils/web-service';
import Select, { components } from 'react-select';

import PaymentBarCode from '../../components/PaymentBarCode';
import PaymentCreditCard from '../../components/PaymentCreditCard';

import Visa from '../../assets/images/img-forma-pagamento-visa.png';
import Master from '../../assets/images/img-forma-pagamento-master.png';
import Elo from '../../assets/images/img-forma-pagamento-elo.png';
import Hipercard from '../../assets/images/img-forma-pagamento-hipercard.png';
import Amex from '../../assets/images/img-forma-pagamento-amex.png';
import ModalUseTerm from '../../components/ModalUseTerm';
import ModalMoreInfo from "../../components/ModalMoreInfo";

const { Option } = components;

const IconOption = props => (
    <Option {...props}>
        {props.data.value === 1 &&
            <IoIosBarcode />
        }

        {props.data.value === 2 &&
            <IoIosCard />
        }

        {` ${props.data.label}`}
    </Option>
);

const DadosPagamento = () => {
    const navigate = useNavigate();
    const [ConsultoresId] = getCookie('__USCONS') ?? '';

    const cotacao = useSelector(state => state.cotacao);
    const venda = useSelector(state => state.venda);
    const load = useSelector(state => state.load);
    const { cartao, boleto } = useSelector((state) => state.valor_cobertura);

    const [showModal, setShowModal] = useState(false);
    const [showModalInformation, setShowModalInformation] = useState(false);

    const [composicaoValores, setComposicaoValores] = useState([]);

    const [formaPagamento, setFormaPagamento] = useState({});

    const [termosUso, setTermosUso] = useState(false);
    const [cupom, setCupom] = useState('');
    const [checkCupom, setCheckCupom] = useState(false);

    const [valorDescontos, setValorDescontos] = useState(0);
    const [valor, setValor] = useState(0);

    const [faturaId, setFaturaId] = useState('');
    const [codigoBarras, setCodigoBarras] = useState('');

    useEffect(() => {
        store.dispatch({ type: 'SET_STEP', payload: 2 });
    }, []);

    useEffect(() => {
        if (!cupom) {
            let dataCotacao = cotacao;
            dataCotacao.CupomDesconto = '';
            store.dispatch({ type: 'SET_COTACAO', payload: dataCotacao });

            setCheckCupom(false);
        }
    }, [cupom]);

    useEffect(() => {
        hanldeGetPrices();
    }, [checkCupom]);

    useEffect(() => {
        hanldeGetPrices();
    }, [formaPagamento]);

    const hanldeGetPrices = async () => {
        if (formaPagamento && formaPagamento.value) {

            let dataCotacao = cotacao;
            dataCotacao.FormaPagamento = formaPagamento.value;
            store.dispatch({ type: 'SET_COTACAO', payload: dataCotacao });

            let pets = cotacao.CotacoesPets.map(item => ({
                Nome: item.Nome,
                DataNascimento: item.DataNascimento,
                Especie: item.Especie,
                RacasId: item.RacasId,
                Sexo: item.Sexo,
                CoberturasId: cotacao.CoberturasId
            }));

            let formDataCatacao = {
                CupomDesconto: cupom ?? cotacao.CupomDesconto,
                Nome: cotacao.Nome,
                Email: cotacao.Email,
                Ddd: cotacao.Ddd,
                Telefone: cotacao.Telefone,
                Site: 1,
                CotacoesOrigensId: 2,
                Cep: cotacao.Cep,
                Logradouro: cotacao.Logradouro,
                Numero: cotacao.Numero,
                Complemento: cotacao.Complemento,
                Bairro: cotacao.Bairro,
                EstadosId: cotacao.EstadosId,
                CidadesId: cotacao.CidadesId,
                FormaPagamento: formaPagamento.value,
                CotacoesPets: pets,
            };

            if (cotacao.ConsultoresId) {
                formDataCatacao.ConsultoresId = cotacao.ConsultoresId
            }

            let data = await api(`Cotacoes/SolicitaCotacaoPet`, 'POST', formDataCatacao);

            if (data.erro) {
                toast.error(data.erro)
                return false;
            }

            //gravar valor à pagar
            setValor(data.ValorAdesao);

            //gravar o id da cotação
            dataCotacao.Id = data.Id;
            store.dispatch({ type: 'SET_COTACAO', payload: dataCotacao });

            //buscar composicao de valores cotação
            data = await api(`CotacoesComposicoes/consultar/?Tipo=1&CotacoesId=${data.Id}`, 'GET', undefined, true);

            if (!data.erro) {
                setComposicaoValores(data);

                let valorDesconto = data.reduce(function (initial, item) {
                    if (item.Valor < 0) {
                        initial += (item.Valor * -1);
                    }
                    return initial;
                }, 0)

                setValorDescontos((valor + valorDesconto));
            }
        }
    }

    const handleCheckCupom = async () => {
        const data = await api(`CampanhasCoberturas/ValidarCupom/?Cupom=${cupom}&TiposCoberturasId=${cotacao.TiposCoberturasId}`);

        if (!data.erro) {
            if (data.Validado) {
                let dataCotacao = cotacao;
                dataCotacao.CupomDesconto = cupom;
                store.dispatch({ type: 'SET_COTACAO', payload: dataCotacao });

                setCheckCupom(true);
                toast.success('Cupom de desconto válido!');
            } else if (!data.Validado) {
                setCheckCupom(false);
                toast.error('Cupom de desconto inválido!');
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let formDataCatacao = venda;

        if (!formDataCatacao.ConsultoresId && cotacao.ConsultoresId) {
            formDataCatacao.ConsultoresId = cotacao.ConsultoresId;
        } else {
            delete formDataCatacao.ConsultoresId;
        }

        formDataCatacao.CotacoesId = cotacao.Id
        formDataCatacao.CupomDesconto = cotacao.CupomDesconto
        formDataCatacao.FormaPagamento = cotacao.FormaPagamento

        formDataCatacao.Clientes.Nome = cotacao.Nome;
        formDataCatacao.Clientes.Email = cotacao.Email;
        formDataCatacao.Clientes.Documento = cotacao.Documento;

        formDataCatacao.Clientes.IndividuosContatos[0].Ddd = cotacao.Ddd;
        formDataCatacao.Clientes.IndividuosContatos[0].Telefone = cotacao.Telefone;
        formDataCatacao.Clientes.IndividuosContatos[0].Nome = cotacao.Nome;

        formDataCatacao.Clientes.IndividuosEnderecos[0].Cep = cotacao.Cep;
        formDataCatacao.Clientes.IndividuosEnderecos[0].Logradouro = cotacao.Logradouro;
        formDataCatacao.Clientes.IndividuosEnderecos[0].Numero = cotacao.Numero;
        formDataCatacao.Clientes.IndividuosEnderecos[0].Bairro = cotacao.Bairro;
        formDataCatacao.Clientes.IndividuosEnderecos[0].Complemento = cotacao.Complemento;
        formDataCatacao.Clientes.IndividuosEnderecos[0].EstadosId = cotacao.EstadosId;
        formDataCatacao.Clientes.IndividuosEnderecos[0].CidadesId = cotacao.CidadesId;

        if (cotacao.FormaPagamento === 2) {
            formDataCatacao.Clientes.IndividuosCartoesCredito[0].NomeCartao = cotacao.NomeCartao;
            formDataCatacao.Clientes.IndividuosCartoesCredito[0].NumeroCartao = cotacao.NumeroCartao;
            formDataCatacao.Clientes.IndividuosCartoesCredito[0].MesValidade = cotacao.MesValidade;
            formDataCatacao.Clientes.IndividuosCartoesCredito[0].AnoValidade = cotacao.AnoValidade;
            formDataCatacao.Clientes.IndividuosCartoesCredito[0].BandeiraCartao = cotacao.BandeiraCartao;
            formDataCatacao.Clientes.IndividuosCartoesCredito[0].CodigoSegurancaCartao = cotacao.CodigoSegurancaCartao;
        } else {
            delete formDataCatacao.Clientes.IndividuosCartoesCredito;
        }

        let data = await api(`Vendas/VendasPet`, 'POST', formDataCatacao);

        if (data.erro) {
            toast.error(data.erro)
            return false;
        }

        toast.success('Venda realizada com sucesso!');

        //Caso a forma de pagamento seja boleto buscar os dados do boleto par o tratamento
        if (cotacao.FormaPagamento === 1) {

            setFaturaId(data.FaturasId);
            data = await api(`Boletos/BuscarBoleto/?FaturasId=${data.FaturasId}`);
            setCodigoBarras(data.CodigoBarras);

        } else if (cotacao.FormaPagamento === 2) {
            window.localStorage.clear();
            window.location.href = 'https://plamev.com.br/checkout/parabens/cartao-de-credito/cotacao';
        }
    }

    const handleFinished = () => {
        window.localStorage.clear();
        window.location.href = 'https://plamev.com.br/checkout/parabens/boleto-bancario/cotacao';
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="mb-3" hidden={codigoBarras}>
                    <h2>Escolha a forma de pagamento:</h2>
                    <Select
                        placeholder="Escolha a forma de pagamento"
                        options={cotacao.FormasPagamentos.map(item => ({
                            value: item.Id,
                            label: `${item.Nome}`,
                            valor: item.Valor
                        }))}
                        components={{ Option: IconOption }}
                        onChange={value => setFormaPagamento(value)}
                    />
                </div>

                <div className="row align-items-stretch justify-content-center mb-4">
                    <div className="col-12 col-sm-6">
                        {(formaPagamento && formaPagamento.value === 1) &&
                            <PaymentBarCode codigoBarras={codigoBarras} faturaId={faturaId} />
                        }

                        {(formaPagamento && formaPagamento.value === 2) &&
                            <PaymentCreditCard />
                        }
                    </div>

                    <div className="col-12 col-sm-6" hidden={codigoBarras}>
                        <div className='plan shadow'>
                            <div className='plan-header'>
                                <div className={`plan-image ${createSlug(cotacao.NomeCobertura)}`}></div>
                                <div className="plan-title">
                                    <p className='mb-0'>Plano</p>
                                    <h2 className='mb-0'>{cotacao.NomeCobertura}</h2>
                                    <div className='plan-info'>
                                        <p className='mb-0'>{cotacao.ProcedimentosCarenciaZero}</p>
                                        <p className='mb-0'>Procedimentos com <strong>Carência Zero</strong></p>
                                    </div>
                                </div>
                            </div>

                            <div className='plan-cupom'>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Insira seu cupom de desconto"
                                    value={cupom}
                                    onChange={({ target }) => setCupom(target.value)}
                                />
                            </div>

                            <div className='plan-details'>
                                <ul>
                                    <li>
                                        <a
                                            className="badge bg-info info-link"
                                            onClick={() => setShowModalInformation(true)}
                                        >
                                            Mais Informações
                                        </a>
                                        <strong><s>{!formaPagamento.value ? 'R$ 0,00' : formaPagamento.value === 1 ? formatMoney(boleto) : formatMoney(cartao)}</s></strong>
                                    </li>
                                    <li>
                                        <span>Total:</span>
                                        <strong>{formatMoney(valor)}</strong>
                                    </li>
                                </ul>
                            </div>

                            <div className='plan-payment-form'>
                                <p>Formas de pagamento:</p>
                                <ul>
                                    <li>
                                        <img
                                            src={Visa}
                                            className="img-fluid"
                                            alt="Forma Pagamento Visa"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={Master}
                                            className="img-fluid"
                                            alt="Forma Pagamento Master"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={Elo}
                                            className="img-fluid"
                                            alt="Forma Pagamento Elo"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={Hipercard}
                                            className="img-fluid"
                                            alt="Forma Pagamento Hipercard"
                                        />
                                    </li>
                                    <li>
                                        <img
                                            src={Amex}
                                            className="img-fluid"
                                            alt="Forma Pagamento Amex"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-center mb-3' hidden={codigoBarras}>
                    <div className='col-12 d-grid'>
                        <div className='d-flex justify-content-center align-items-center gap-1'>
                            <input
                                type="checkbox"
                                id='use-term'
                                checked={termosUso}
                                onChange={() => setTermosUso(!termosUso)}
                            />
                            <label htmlFor='use-term'>Li e estou de acordo com o</label>
                            <a href='#' onClick={() => setShowModal(true)}>Contrato</a>
                        </div>
                    </div>
                </div>

                <div className='row mb-3' hidden={codigoBarras}>
                    <div className='col-12 col-sm-3'>
                        <Link to="/" className='btn btn-secondary btn-rounded'>
                            <IoMdArrowBack /> Voltar
                        </Link>
                    </div>
                    <div className='col-12 col-sm-6 d-grid'>
                        {(!cupom || (cupom && checkCupom)) &&
                            <button type='submit' disabled={(!termosUso || load)} className='btn btn-primary btn-rounded'>
                                {load &&
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                }
                                {!load &&
                                    `${formaPagamento.value === 1 ? 'Gerar Boleto' : 'Confirmar'}`
                                }
                            </button>
                        }

                        {(cupom && !checkCupom) &&
                            <button type='button' onClick={handleCheckCupom} className='btn btn-primary btn-rounded'>
                                Validar Cupom
                            </button>
                        }
                    </div>
                </div>

                <div className='row mb-3 justify-content-center align-items-center' hidden={!codigoBarras}>
                    <div className='col-12 col-sm-6 d-grid'>
                        <button type="button" onClick={handleFinished} className='btn btn-primary btn-rounded'>
                            <IoIosCheckmark /> Finalizar
                        </button>
                    </div>
                </div>
            </form>
            <ModalUseTerm show={showModal} onHide={() => setShowModal(false)} />
            <ModalMoreInfo
                show={showModalInformation}
                onHide={() => setShowModalInformation(false)}
                composicao={composicaoValores}
            />
        </>
    )
}

export default DadosPagamento;