import Logomarca from '../../assets/images/logomarca.png';
import CreditCardChip from '../../assets/images/credit-card-chip.png';
import ReactCardFlip from 'react-card-flip';

const CreditCarsFront = ({ flagCreditCard, cardNumber, cardName, cardValidate }) => {
    return (
        <div className="credit-card">
            <div className='logomarca'>
                <img
                    src={Logomarca}
                    alt="Plamev Pet"
                />
            </div>

            <div className='d-flex justify-content-between align-items-center'>
                <div className='credit-card-chip'>
                    <img
                        src={CreditCardChip}
                        alt="Plamev Pet"
                    />
                </div>

                <div className='credit-card-flag'>
                    {flagCreditCard &&
                        <img
                            src={flagCreditCard}
                            alt="Bandera do cartão"
                        />
                    }
                </div>
            </div>

            <div className='credit-card-info'>
                <div className='credit-card-number'>
                    <p className='mb-0' data-text="XXXX XXXX XXXX XXXX">{cardNumber}</p>
                </div>

                <div className='credit-card-name'>
                    <span>Nome</span>
                    <p className='mb-0' data-text="Maria da Silva Pereira">{cardName}</p>
                </div>

                <div className='credit-card-date'>
                    <span>Validade</span>
                    <p className='mb-0' data-text="20/2022">{cardValidate}</p>
                </div>
            </div>
        </div>
    )
}

const CreditCarsBack = ({ cardCodeSecurity }) => {
    return (
        <div className="credit-card">
            <div className='carg-stripe-black'></div>
            <div className='carg-stripe-code'>
                <p className='mb-0'>{cardCodeSecurity}</p>
            </div>
        </div>
    )
}

const CreditCard = ({ flagCreditCard, cardNumber, cardName, cardValidate, cardCodeSecurity, toTorn }) => {    
    return (
        <ReactCardFlip isFlipped={toTorn} flipDirection="horizontal">
            <CreditCarsFront flagCreditCard={flagCreditCard} cardNumber={cardNumber} cardName={cardName} cardValidate={cardValidate} />
            <CreditCarsBack cardCodeSecurity={cardCodeSecurity} />
        </ReactCardFlip>
    )
}

export default CreditCard;