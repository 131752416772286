import {useRoutes} from "react-router-dom";

import Template from "../components/Template";
import TemplateBlank from "../components/TemplateBlank"

import DadosPagamento from "../views/DadosPagamento";
import DadosPessoais from "../views/DadosPessoais";
import PaginaParabens from "../views/PaginaParabens"

const Routers = () => {
    return useRoutes([
        {
            path: '/',
            element: <Template/>,
            children: [
                {index: true, element: <DadosPessoais/>},

                {path: ':cobertura/:tiposCobertura/:cupomDesconto/:loginConsultor', element: <DadosPessoais/>},
                {path: ':cobertura/:tiposCobertura/:loginConsultor', element: <DadosPessoais/>},
                {path: ':cobertura/:tiposCobertura', element: <DadosPessoais/>},

                {path: ':loginConsultor/:origem', element: <DadosPessoais/>},
                {path: ':loginConsultor', element: <DadosPessoais/>},

                {path: 'pagamento', element: <DadosPagamento/>}
            ]
        },
        {
            path: '/parabens',
            element: <TemplateBlank/>,
            children: [
                {index: true, element: <PaginaParabens/>},
                {path: ':pagina', element: <PaginaParabens/>},
                {path: ':pagina/:localAcesso', element: <PaginaParabens/>}
            ]
        }

    ]);
}

export default Routers;