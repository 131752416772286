import Logomarca from '../../assets/images/logomarca.png';
import {getCookie} from "../../utils/functions";

const TemplateHeader = () => {
    const loginCOnsultor = getCookie('__USCONSLOG') || '';
    return (
        <div className="row justify-content-center mb-5 mt-4">
            <div className="col-12 col-sm-4 d-flex justify-content-center">
                <a href={`http://plamev.com.br/cotacao/${loginCOnsultor}`} rel="noreferrer">
                    <img
                        src={Logomarca}
                        alt="Plamev Checkout Externo"
                        className="img-fluid"
                    />
                </a>
            </div>
        </div>
    );
}

export default TemplateHeader;