import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';

// import {PersistGate} from 'redux-persist/integration/react'
// import {store, persistor} from './redux'

import store from './redux'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './scss/index.scss';

import Views from './views';

ReactDOM.render(
    <Provider store={store}>
        <Views/>
    </Provider>
    , document.getElementById('root'));