import {IoIosWarning, IoIosCopy, IoIosPrint} from 'react-icons/io';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {URL_BOLETO} from "../../utils/constantes";

const PaymentBarCode = ({codigoBarras, faturaId}) => {
    return (
        <div className="payment-bar-code shadow">
            {(codigoBarras && faturaId) &&
            <>
                <h2>Dados do Boleto</h2>
                <div className="bar-code-info">
                    <p>{codigoBarras}</p>

                    <CopyToClipboard text={codigoBarras}>
                        <button type="button" className="btn btn-primary btn-rounded">
                            <IoIosCopy/> Copiar Código Barras
                        </button>
                    </CopyToClipboard>

                    <a
                        href={`${URL_BOLETO}${faturaId}`}
                        target="_blank"
                        rel="noreferrer"
                        className="btn btn-primary btn-rounded"
                    >
                        <IoIosPrint/> Imprimir Fatura
                    </a>
                </div>
            </>
            }
            <div className="bar-code-warning">
                <div className="title">
                    <div className='icon'>
                        <IoIosWarning/>
                    </div>
                    <h3>Atente-se aos detalhes:</h3>
                </div>
                <ul>
                    <li>- Boleto (somente à vista).</li>
                    <li>- Pagamento com boleto bancário levam 3 dias úteis para serem compensados.</li>
                    <li>- Atente-se a data de vencimento do boleto. Você pode pagar o boleto em qualquer banco ou casa
                        lotérica até o dia do vencimento.
                    </li>
                    <li>- Depois do pagamento, verifique seu e-mail para receber as orientações para ativação do
                        plano.
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default PaymentBarCode;