import Elo from '../assets/images/elo.png';
import Visa from '../assets/images/visa.png';
import MasterCard from '../assets/images/mastercard.png';
import Amex from '../assets/images/american_express.png';
import Hipercard from '../assets/images/hipercard.png';
import Diners from '../assets/images/diners_club.jpg';
import Discover from '../assets/images/discover.jpg';
import JCB from '../assets/images/jcb.png';
import Aura from '../assets/images/aura.jpg';

export const PROD = true;
export const API_URL = PROD ? 'https://service.plamev.com.br/' : 'https://service.hm.plamev.com.br/';
export const URL_BOLETO = PROD ? 'https://boleto.plamev.com.br/visualizar/' : 'https://boleto.hm.plamev.com.br/visualizar/';

export const TOKEN = "qrmLJ2bSZdAYXnWfsMmiJdmktCWJvx56sFYmfUYLrFhUt1qWrxfugCtsnC9Bc0WVov1dcdkpipqmJC9WlYfVvYokokflcnWRsOhUlqozH2Pun1UVrYtUcYWslC1vtYkWrxfulCfktCWJvx56sFYmfUYLrFhUt1qWrxfulqWptCRJvxWvsOfvJdfRHaomJC9WqrmLckWrlMUjXRAs5vxzaJOOO";

export const HEADER = {
    'Content-Type': "application/json",
    Authorization: TOKEN
}

export const PET = {
    Nome: '',
    DataNascimento: ''
}

export const COTACAO = {
    Nome: "",
    Email: "",
    Ddd: "",
    Telefone: "",
    Site: "",
    CotacoesPets: [],
    Cep: "",
    Logradouro: "",
    Numero: "",
    Complemento: "",
    Bairro: "",
    EstadosId: "",
    CidadesId: "",
    FormaPagamento: "",
    CoberturasId: "",
    TiposCoberturasId: "",
    CoberturasIdDetalhes: "",
    NomeCobertura: "",
    Id: "",
    Documento: "",
    CotacoesOrigensId: "",
    QtdPets: "",
    CartaoCredito: "",
    CupomDesconto: "",
    Externo: "",
    CidadesNome: "",
    EstadosNome: "",
    OrigensId: "",
    OrigemParalela: "",
};

export const CARTOES = [
    {
        Id: 3,
        Nome: 'Elo',
        Regex: /^(40117[8-9]|431274|438935|451416|457393|45763[1-2]|506(699|7[0-6][0-9]|77[0-8])|509\d{3}|504175|627780|636297|636368|65003[1-3]|6500(3[5-9]|4[0-9]|5[0-1])|6504(0[5-9]|[1-3][0-9])|650(4[8-9][0-9]|5[0-2][0-9]|53[0-8])|6505(4[1-9]|[5-8][0-9]|9[0-8])|6507(0[0-9]|1[0-8])|65072[0-7]|6509(0[1-9]|1[0-9]|20)|6516(5[2-9]|[6-7][0-9])|6550([0-1][0-9]|2[1-9]|[3-4][0-9]|5[0-8]))\d{10}$/,
        Imagem: Elo,
      },
      {
        Id: 1,
        Nome: 'Visa',
        Regex: /^4\d{12}(\d{3})?$/,
        Imagem: Visa,
      },
      {
        Id: 2,
        Nome: 'Mastercard',
        Regex: /^(?:4[0-9]{12}(?:[0-9]{3})?|[25][1-7][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/,
        Imagem: MasterCard,
      },
      {
        Id: 4,
        Nome: 'Amex',
        Regex: /^3[47]\d{13}$/,
        Imagem: Amex,
      },
      {
        Id: 5,
        Nome: 'Hipercard',
        Regex: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
        Imagem: Hipercard,
      },
      {
        Id: 6,
        Nome: 'Discover',
        Regex: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        Imagem: Discover,
      },
      {
        Id: 7,
        Nome: 'Diners',
        Regex: /^3(0[0-5]|[68]\d)\d{11}$/,
        Imagem: Diners,
      },
      {
        Id: 8,
        Nome: 'JCB',
        Regex: /^(?:2131|1800|35\d{3})\d{11}$/,
        Imagem: JCB,
      },
      {
        Id: 9,
        Nome: 'Aura',
        Regex: /^(5078\d{2})(\d{2})(\d{11})$/,
        Imagem: Aura,
      },
];