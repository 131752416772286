import numeral from 'numeral';
import 'numeral/locales/pt-br';

export const onlyNumbers = (value) => {
    return value.replace(/[^0-9]/g, '');
}

export function getArrayFromResponse(value) {
    let index = [];
    let length = Object.keys(value).length;

    if (length > 0) {
        for (let i = 1; i <= length; i++) {
            index.push(value[i]);
        }
    }

    return index;
}

export function formatMoney(value) {
    numeral.locale('en');
    const newValue = numeral(value ? value : 0.0).value();
    numeral.locale('pt-br');
    return numeral(newValue).format('$ 0,0.00');
}

export function createSlug(value) {
    return value
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}