import {createStore} from 'redux';

import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const INITIAL_STATE = {
    load: false,
    step: 1,
    cotacao: {
        ConsultoresId: "",
        Nome: "",
        Email: "",
        Ddd: "",
        Telefone: "",
        Site: 1,
        CotacoesPets: [],
        Cep: "",
        Logradouro: "",
        Numero: "",
        Complemento: "",
        Bairro: "",
        EstadosId: "",
        CidadesId: "",
        FormaPagamento: 2,
        CoberturasId: "",
        TiposCoberturasId: "",
        CoberturasIdDetalhes: "",
        NomeCobertura: "",
        Id: "",
        Documento: "",
        CotacoesOrigensId: 2,
        QtdPets: "",
        CartaoCredito: "",
        CupomDesconto: "",
        Externo: "",
        CidadesNome: "",
        EstadosNome: "",
        OrigensId: "SITE",
        OrigemParalela: "",
        ValorBoleto: {},
        ValorCartaoCredito: {},
        ProcedimentosCarenciaZero: 0,
        FormasPagamentos: [],
        NomeCartao: '',
        NumeroCartao: '',
        MesValidade: '',
        AnoValidade: '',
        BandeiraCartao: '',
        CodigoSegurancaCartao: '',
        Clientes: {
            Nome: '',
            Email: '',
            Documento: '',
            IndividuosCartoesCredito: [
                {
                    NomeCartao: '',
                    NumeroCartao: '',
                    MesValidade: '',
                    AnoValidade: '',
                    BandeiraCartao: '',
                    CodigoSegurancaCartao: ''
                }
            ],
            IndividuosContatos: [
                {
                    Ddd: '',
                    Telefone: '',
                    Nome: '',
                    Whatsapp: 0,
                    Padrao: 1
                }
            ],
            IndividuosEnderecos: [
                {
                    Cep: '',
                    Logradouro: '',
                    Numero: '',
                    Complemento: '',
                    Bairro: '',
                    EstadosId: '',
                    CidadesId: '',
                    Padrao: 1
                }
            ]
        }
    },
    venda: {
        CotacoesId: '',
        ConsultoresId: '',
        CupomDesconto: '',
        FormaPagamento: '',
        Clientes: {
            Nome: '',
            Email: '',
            Documento: '',
            IndividuosCartoesCredito: [
                {
                    NomeCartao: '',
                    NumeroCartao: '',
                    MesValidade: '',
                    AnoValidade: '',
                    BandeiraCartao: '',
                    CodigoSegurancaCartao: ''
                }
            ],
            IndividuosContatos: [
                {
                    Ddd: '',
                    Telefone: '',
                    Nome: '',
                    Whatsapp: 0,
                    Padrao: 1
                }
            ],
            IndividuosEnderecos: [
                {
                    Cep: '',
                    Logradouro: '',
                    Numero: '',
                    Complemento: '',
                    Bairro: '',
                    EstadosId: '',
                    CidadesId: '',
                    Padrao: 1
                }
            ]
        }
    },
    valor_cobertura:{
        cartao: 0,
        boleto: 0
    }

}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'OPEN_LOAD':
            return {...state, load: true};
        case 'CLOSE_LOAD':
            return {...state, load: false};
        case 'SET_STEP':
            return {...state, step: action.payload};
        case 'SET_CARTAO':
            return {...state, valor_cobertura: {...state.valor_cobertura,...action.payload}};  
        case 'SET_COTACAO':
            return {...state, cotacao: {...state.cotacao, ...action.payload}}
        default:
            return state;
    }
}


const store = createStore(reducer);
export default store;

/*
const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

let store = createStore(persistedReducer)
let persistor = persistStore(store)

export { store, persistor };
 */