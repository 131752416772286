import { useState } from 'react';
import { API_URL, HEADER } from '../utils/constantes';
import { getArrayFromResponse } from '../utils/functions';

const useApi = () => {
    const [loading, setLoading] = useState(false);

    const fetchApi = async (url, method = 'GET', body, parse = false) => {
        setLoading(true);

        let headers = HEADER;

        const response = await fetch(`${API_URL}${url}`, {
            headers,
            method,
            body: body ? JSON.stringify(body) : undefined,
        });

        let data = await response.json();
        setLoading(false);

        if (parse) {
            data = getArrayFromResponse(data);
        }

        return { response, data };
    }

    return { loading, fetchApi };

}

export default useApi;