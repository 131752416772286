import appstore from '../../assets/images/icone-app-store.png';
import googleplay from '../../assets/images/icone-google-play.png';
import appcelular from '../../assets/images/icone-celular-aplicativo.png';
import imgcachorros from '../../assets/images/img-cachorros.png'

import {AiOutlineInstagram, AiFillYoutube} from 'react-icons/ai';
import {RiFacebookFill} from 'react-icons/ri'

const PaginaParabens = () => {
    return (
        <>
            <div id='img-dogs'>
                <img src={imgcachorros} alt="Cachorros felizes"/>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="container-congratulation">
                        <div className="title-page  mb-3">
                            <h1 className="text-center font-weight-bold">OBRIGADO POR ADQUIRIR O PLANO!</h1>
                        </div>
                        <div className="rectangle-gray">
                            <div className="circle">!</div>
                            <div className='text-center'>Agora, ative em três passos:</div>
                        </div>
                        <div className='row my-3 subtitle my-3'>
                            <p className='text-center'>Baixe nosso aplicativo Plamev Appet</p>
                        </div>
                        <div className="row icons-store">
                            <div className='col-6 '>
                                <a
                                    href="https://apps.apple.com/us/app/plamev-appet/id1459384293"
                                    rel="noreferrer"
                                    target={"_blank"}
                                >
                                    <img src={appstore} alt="Disponivel na App Store"/>
                                </a>
                            </div>
                            <div className='col-6'>
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.plamevcliente&hl=pt_BR"
                                    rel="noreferrer"
                                    target={"_blank"}
                                >
                                    <img src={googleplay} alt="Disponivel na Google Play"/>
                                </a>
                            </div>
                        </div>
                        <div>
                            <ul className='row list-steps'>
                                <li>
                                    <div className='circle'>1</div>
                                    <div>
                                        Baixe o aplicativo <span>Plamev Appet</span> e clique no menu <span>Plano</span>
                                    </div>

                                </li>
                                <li>
                                    <div className='circle'>2</div>
                                    <div><span>Faça o login</span> (seu usuário e sua senha é o número do seu CPF)</div>
                                </li>
                                <li>
                                    <div className='circle'>3</div>
                                    <div><span>Ative a ID PET,</span> clicando no menu <span>Plano - <br/> Pets</span> e
                                        siga as instruções
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className='footer-congratulation-page my-3'>
                            <div>
                                <p>Faça o gerenciamento do seu plano com facilidade e agilidade com o aplicativo Plamev
                                    Appet.</p>
                            </div>
                            <img src={appcelular} alt="Celular aplicativo Mockup"/>
                        </div>

                        <ul className="list-social-media my-3">
                            <li>
                                <a
                                    href="https://www.instagram.com/plamevoficial/"
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    <AiOutlineInstagram/>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/channel/UCuynEeqh1JLpF5fUKtL7Lug"
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    <AiFillYoutube/>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.facebook.com/Plamev/"
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    <RiFacebookFill/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaginaParabens;

