import { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { IoIosAddCircleOutline, IoMdTrash } from 'react-icons/io';
import { getCookie, onlyNumbers, setCookie } from '../../utils/functions';
import { toast } from 'react-toastify';
import store from '../../redux';
import { api } from '../../utils/web-service';

import InputMask from 'react-input-mask';
import Select from 'react-select';

const DadosPessoais = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const origem = getCookie('__USCONSORI') ?? 'PlamevInterno';

    const cotacao = useSelector(state => state.cotacao);
    const load = useSelector(state => state.load);
    const inputRef = useRef(null);

    const [maskPhone, setMaskPhone] = useState('(99) 99999 9999');

    const [pets, setPets] = useState([{ Nome: '', DataNascimento: '', Especie: 2, RacasId: 'SEMRACA2', Sexo: 1 }]);

    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);

    const [nome, setNome] = useState('');
    const [documento, setDocumento] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');

    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [estado, setEstado] = useState('');
    const [cidade, setCidade] = useState('');

    const [leadsId, setLeadsId] = useState(null);

    useEffect(() => {
        (async () => {
            const data = await api('Estados/consultar/?order=Nome ASC', 'GET', undefined, true);
            if (!data.erro) {
                setEstados(data);
                store.dispatch({ type: 'SET_STEP', payload: 1 });
            }
        })()
    }, []);

    useEffect(() => {
        setEmail(searchParams.get('email') || '');
        setTelefone(searchParams.get('telefone') ? `(${searchParams.get('ddd')}) ${searchParams.get('telefone')}` : '');

        if (searchParams.get('leads')) {
            setLeadsId(searchParams.get('leads'));
        }

    }, []);

    useEffect(() => {
        if (params) {
            handleCobertura();
            handleCupomDesconto();
            handleLoginConsultor();
        }
    }, [params])

    useEffect(() => {
        if (cotacao && cotacao.Nome) {
            setNome(cotacao.Nome);
            setDocumento(cotacao.Documento);

            setEmail(cotacao.Email);
            setTelefone(`${cotacao.Ddd}${cotacao.Telefone}`);

            setPets(cotacao.CotacoesPets);
            setCep(cotacao.Cep);
            setLogradouro(cotacao.Logradouro);
            setNumero(cotacao.Numero);
            setComplemento(cotacao.Complemento);
            setBairro(cotacao.Bairro);
            setEstado({ value: cotacao.EstadosId, label: cotacao.EstadosNome });
            setCidade({ value: cotacao.CidadesId, label: cotacao.CidadesNome });
        }
    }, [cotacao]);

    useEffect(() => {
        (async () => {
            if (estado.value) {
                const data = await api(`Cidades/consultar/?EstadosId=${estado.value}&order=Nome ASC`, 'GET', undefined, true);
                if (!data.erro) {
                    setCidades(data);
                }
            }
        })()
    }, [estado]);

    useEffect(() => {
        (async () => {
            const Cep = onlyNumbers(cep);
            if (cep && Cep.length === 8 && cotacao.Cep !== Cep) {
                const data = await api('IndividuosEnderecos/ConsultaCep', 'POST', { Cep });

                if (data.erro) {
                    toast.error(data.erro);

                    setLogradouro('');
                    setNumero('');
                    setComplemento('');
                    setBairro('');
                    setEstado('');
                    setCidade('');

                    return false;
                }

                setLogradouro(data.logradouro);
                setBairro(data.bairro);
                setEstado({ value: data.estado_id, label: data.estado_nome });
                setCidade({ value: data.cidade_id, label: data.cidade_nome });

                inputRef.current.focus();
            }
        })()
    }, [cep, cotacao.Cep]);

    const handleCobertura = useCallback(async () => {
        if (params && params.cobertura && params.tiposCobertura) {
            const CoberturasId = params.cobertura;
            const TipoCoberturasId = params.tiposCobertura;

            const data = await api(`Coberturas/BuscarCoberturasComPreco/?Id=${CoberturasId}&TiposCoberturasId=${TipoCoberturasId}&EstadosId=NULL&CidadesId=NULL&Valores=true&order=Ordem`);

            console.log(data);

            if (!data.erro) {
                let formData = cotacao;

                formData.CoberturasId = data['Coberturas'][0].Id;
                formData.TiposCoberturasId = data['Coberturas'][0].TiposCoberturasId;
                formData.NomeCobertura = data['Coberturas'][0].Nome;

                formData.ValorBoleto = data['Coberturas'][0].Boleto;
                formData.ValorCartaoCredito = data['Coberturas'][0].Cartao;
                formData.ProcedimentosCarenciaZero = data['Coberturas'][0].CarenciaZero;
                formData.FormasPagamentos = [
                    {
                        Id: 2,
                        Nome: 'Cartão de Crédito',
                        Valor: data['Coberturas'][0].Cartao.Composicao.ValorTotal
                    },
                    {
                        Id: 1,
                        Nome: 'Boleto',
                        Valor: data['Coberturas'][0].Boleto.Composicao.ValorTotal
                    }
                ];

                store.dispatch({ type: 'SET_COTACAO', payload: formData });
                store.dispatch({ type: 'SET_CARTAO', payload: { cartao: data['Coberturas'][0].Cartao.Composicao.ValorTotal, boleto: data['Coberturas'][0].Boleto.Composicao.ValorTotal } });
            }
        }
    }, [params]);

    const handleCupomDesconto = useCallback(() => {
        if (params && params.cupomDesconto) {
            let dataCotacao = cotacao;
            dataCotacao.CupomDesconto = params.cupomDesconto;
            store.dispatch({ type: 'SET_COTACAO', payload: dataCotacao });
        }
    }, [params]);

    const handleLoginConsultor = useCallback(async () => {
        setCookie('__USCONSCON', '', 1)
        setCookie('__USCONS', '', 1)
        setCookie('__USCONSLOG', '', 1)
        setCookie('__USCONSORI', '', 1)

        if (params && params.loginConsultor) {
            let data = await api(`Consultores/ConsultarLogin/${params.loginConsultor}`);

            if (!data.erro) {
                let dataCotacao = cotacao;
                dataCotacao.ConsultoresId = data.Id;
                store.dispatch({ type: 'SET_COTACAO', payload: dataCotacao });

                setCookie('__USCONS', data.Id, 1)
                setCookie('__USCONSLOG', params.loginConsultor || '', 1)
                setCookie('__USCONSORI', params.origem ?? 'PlamevInterno', 1)
            }

            data = await api(`Consultores/ConsultarContato/${params.loginConsultor}`);

            if (data.Contato.TipoContato && parseInt(data.Contato.TipoContato) === 1) {
                setCookie('__USCONSCON', `${onlyNumbers(data.Contato.Ddi)}${onlyNumbers(data.Contato.Ddd)}${onlyNumbers(data.Contato.Telefone)}`, 1);
            }
        }
    }, [params]);

    const handleAddNewPet = () => {
        let listaPets = pets;
        listaPets.push({ Nome: '', DataNascimento: '', Especie: 2, RacasId: 'SEMRACA2', Sexo: 1 });
        setPets([...listaPets]);
    }

    const handleRemovePet = (position) => {
        let listaPets = pets;
        listaPets.splice(position, 1);
        setPets([...listaPets]);
    }

    const hanldeChangePet = (pet, position) => {
        let listaPets = pets;
        listaPets[position][pet.name] = pet.value;
        setPets([...listaPets]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let dataCotacao = cotacao;

        dataCotacao.Nome = nome;
        dataCotacao.Documento = documento;
        dataCotacao.Email = email;
        dataCotacao.Ddd = onlyNumbers(telefone).substr(0, 2);
        dataCotacao.Telefone = onlyNumbers(telefone).substr(2);

        dataCotacao.OrigemParalela = origem;

        dataCotacao.CotacoesPets = pets;

        dataCotacao.QtdPets = pets.length;

        dataCotacao.Cep = cep;
        dataCotacao.Logradouro = logradouro;
        dataCotacao.Numero = numero;
        dataCotacao.Bairro = bairro;
        dataCotacao.Complemento = complemento;
        dataCotacao.EstadosId = estado.value;
        dataCotacao.EstadosNome = estado.label;
        dataCotacao.CidadesId = cidade.value;
        dataCotacao.CidadesNome = cidade.label;

        store.dispatch({ type: 'SET_COTACAO', payload: dataCotacao });

        let urlLead = 'Leads/NovoLeadSite';

        if (leadsId) {
            urlLead = `Leads/atualizar/${leadsId}`;
        }

        const data = await api(`${urlLead}`, 'POST', cotacao);

        navigate('/pagamento');
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="data-personal">
                <h2>Dados Pessoais</h2>

                <div className="row mb-3">
                    <div className="col-12 col-md-6 mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Nome"
                            required
                            value={nome}
                            onChange={({ target }) => setNome(target.value)}
                        />
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <InputMask
                            type="text"
                            className="form-control"
                            mask="999.999.999-99"
                            maskChar=""
                            placeholder="CPF"
                            required
                            value={documento}
                            onChange={({ target }) => setDocumento(target.value)}
                        />
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <input
                            type="email"
                            className="form-control"
                            placeholder="E-mail"
                            required
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                        />
                    </div>

                    <div className="col-12 col-md-6 mb-3">
                        <InputMask
                            type="text"
                            className="form-control"
                            mask={maskPhone}
                            maskChar=""
                            required
                            placeholder="Telefone"
                            onKeyPress={({ target }) => {
                                if (onlyNumbers(target.value).length === 9) {
                                    setMaskPhone('(99) 9999-9999');
                                } else if (onlyNumbers(target.value).length === 10) {
                                    setMaskPhone('(99) 99999-9999');
                                }
                            }}
                            value={telefone}
                            onChange={({ target }) => setTelefone(target.value)}
                        />
                    </div>
                </div>
            </div>

            <div className="data-pets mb-3">
                <h2>Dados do pet</h2>

                <div className="lista-pets">
                    {pets.map((pet, index) => (
                        <div key={index}
                            className={`row align-items-center ${index !== 0 ? 'border-top border-1 pt-3 mb-3' : ''}`}>
                            <div className={index === 0 ? `col-12 col-sm-6 mb-3` : 'col-12 col-sm-6'}>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Nome do Peludinho"
                                    value={pet.Nome}
                                    name="Nome"
                                    required
                                    onChange={({ target }) => hanldeChangePet(target, index)}
                                />
                            </div>
                            <div className={index === 0 ? `col-12 col-sm-6 mb-3` : 'col-8 col-sm-5 mt-3'}>
                                <input
                                    type="text"
                                    onFocus={(e) => e.target.type = 'date'}
                                    className="form-control"
                                    placeholder="Data Nascimento"
                                    value={pet.DataNascimento}
                                    name="DataNascimento"
                                    required
                                    onChange={({ target }) => hanldeChangePet(target, index)}
                                />
                            </div>
                            {index !== 0 &&
                                <div className={`col-4 col-sm-1 d-flex justify-content-end ${index !== 0 ? 'mt-3' : ''}`}>
                                    <button type="button" onClick={() => handleRemovePet(index)}
                                        className='btn-trash btn-rounded text-danger'>
                                        <IoMdTrash />
                                    </button>
                                </div>
                            }
                        </div>
                    ))}
                </div>

                <button type='button' onClick={handleAddNewPet} className='btn btn-default d-flex align-items-center'>
                    <IoIosAddCircleOutline />
                    Adicionar outro pet
                </button>
            </div>

            <div className="data-address mb-3">
                <h2>Endereço</h2>

                <div className="row align-items-center">
                    <div className="col-12 col-sm-6 mb-3">
                        <InputMask
                            type="text"
                            className="form-control"
                            mask="99999-999"
                            maskChar=""
                            placeholder="CEP"
                            required
                            value={cep}
                            onChange={({ target }) => setCep(onlyNumbers(target.value))}
                        />
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                        <a
                            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                            rel="noreferrer"
                            className="btn btn-default"
                            target="_blank"
                        >Não sabe seu CEP?</a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-8 mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Logradouro"
                            required
                            value={logradouro}
                            onChange={({ target }) => setLogradouro(target.value)}
                        />
                    </div>

                    <div className="col-12 col-sm-4 mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Número"
                            ref={inputRef}
                            required
                            value={numero}
                            onChange={({ target }) => setNumero(target.value)}
                        />
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Bairro"
                            required
                            value={bairro}
                            onChange={({ target }) => setBairro(target.value)}
                        />
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Complemento"
                            value={complemento}
                            onChange={({ target }) => setComplemento(target.value)}
                        />
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                        <Select
                            value={estado}
                            onChange={value => setEstado(value)}
                            required
                            placeholder="Selecione o Estado"
                            options={estados.map(item => ({ value: item.Id, label: item.Nome }))}
                        />
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                        <Select
                            value={cidade}
                            onChange={value => setCidade(value)}
                            required
                            placeholder="Selecione a Cidade"
                            options={cidades.map(item => ({ value: item.Id, label: item.Nome }))}
                        />
                    </div>
                </div>
            </div>

            <div className='row justify-content-center'>
                <div className='col-12 col-sm-6 d-grid'>
                    <button type='submit' disabled={load} className='btn btn-primary btn-rounded'>
                        {load &&
                            <span className="spinner-border spinner-border-sm"></span>
                        }
                        {!load &&
                            <>Continue</>
                        }
                    </button>
                </div>
            </div>
        </form>
    )
}

export default DadosPessoais;