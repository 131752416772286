import { Outlet } from "react-router-dom";

import TemplateHeader from "../TemplateHeader";
import Steps from "../Steps";

const Template = () => {
    return (
        <div className="wrapper">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <TemplateHeader />

                        <div className="card">
                            <div className="card-body">
                                <div className="title">
                                    <h1 className="mb-3 text-center">Você está a um passo de adquirir o maior e mais completo plano de saúde pet!</h1>
                                </div>

                                <Steps />

                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Template;