import {useEffect} from "react";

import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from 'react-toastify';

import Routers from "../routers";
import TagManager from 'react-gtm-module';

const Views = () => {

    useEffect(() => {
        TagManager.initialize({gtmId: 'GTM-W369BJ4'});
    }, []);

    return (
        <>
            <ToastContainer theme="colored" position="top-center" closeOnClick autoClose={3000}/>

            <BrowserRouter>
                <Routers/>
            </BrowserRouter>
        </>
    )
}

export default Views;