import { HEADER, API_URL } from './constantes';
import { getArrayFromResponse } from './functions';
import store from '../redux';

export const api = async (url, method = 'GET', body = undefined, parse = false) => {
    let headers = HEADER;

    store.dispatch({ type: 'OPEN_LOAD' });

    const request = new Request(API_URL + url, {
        headers: headers,
        method: method,
        body: body ? JSON.stringify(body) : undefined,
    });

    const response = await fetch(request);
    const data = await response.json();

    store.dispatch({ type: 'CLOSE_LOAD' });

    if (response.ok) {
        if (parse) return getArrayFromResponse(data);
        return data;
    }

    return data;
}