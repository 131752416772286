import { useSelector } from "react-redux";

const Steps = () => {
    const step = useSelector(state => state.step);
    return (
        <ul className="steps">
            <li>
                <div className={`bollet ${step === 1 || step === 2 ? 'active' : ''}`}></div>
            </li>
            <li>
                <div className={`bollet ${step === 2 ? 'active' : ''}`}></div>
            </li>
            <li>
                Passo {step} de 2
            </li>
        </ul>
    );
}

export default Steps