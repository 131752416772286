import { Outlet } from "react-router-dom";

const TemplateBlank = ()=>{
    return(
    <div className="wrapper-blank justify-content-cente align-items-center">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">                    
                    <Outlet />        
                </div>
            </div>
        </div>
    </div>
    );
}

export default TemplateBlank;