import {useState, useEffect} from 'react';

import {CARTOES} from '../../utils/constantes';
import {onlyNumbers} from '../../utils/functions';
import store from '../../redux';

import InputMask from 'react-input-mask';
import CreditCard from '../CreditCard';
import {useSelector} from 'react-redux';

const PaymentCreditCard = () => {
    const cotacao = useSelector(state => state.cotacao);

    const [toTorn, setToTorn] = useState(false);
    const [flagCreditCard, setFlagCreditCarf] = useState('');

    const validade = cotacao.MesValidade ? `${cotacao.MesValidade}/${cotacao.AnoValidade}` : '';

    useEffect(() => {
        if (cotacao && cotacao.NumeroCartao) {
            let dataCotacao = cotacao;

            const number = onlyNumbers(cotacao.NumeroCartao);
            const imgCreditCard = CARTOES.find(item => item.Regex.test(number))

            setFlagCreditCarf(imgCreditCard ? imgCreditCard.Imagem : '');

            if (imgCreditCard && imgCreditCard.Id) {
                dataCotacao.BandeiraCartao = imgCreditCard.Id;
                store.dispatch({type: 'SET_COTACAO', payload: dataCotacao});
            }

        }
    }, [cotacao.NumeroCartao]);

    const handelChangeCreditCard = (value, name) => {
        let dataCotacao = cotacao;

        if (name !== 'validade') {
            dataCotacao[name] = value;
        } else {
            dataCotacao.MesValidade = value.split('/')[0];
            dataCotacao.AnoValidade = value.split('/')[1];
        }

        store.dispatch({type: 'SET_COTACAO', payload: dataCotacao});
    }

    return (
        <div className="payment-credit-card shadow">
            <CreditCard
                flagCreditCard={flagCreditCard}
                cardNumber={cotacao.NumeroCartao}
                cardName={cotacao.NomeCartao}
                cardValidate={validade}
                cardCodeSecurity={cotacao.CodigoSegurancaCartao}
                toTorn={toTorn}
            />

            <div className="mb-3">
                <InputMask
                    mask="9999 9999 9999 999999"
                    maskChar=""
                    className="form-control"
                    placeholder='Número do cartão'
                    value={cotacao.NumeroCartao}
                    onChange={({target}) => handelChangeCreditCard(target.value, 'NumeroCartao')}
                    onFocus={() => setToTorn(false)}
                />
            </div>

            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Nome do cartão"
                    value={cotacao.NomeCartao}
                    onChange={({target}) => handelChangeCreditCard(target.value, 'NomeCartao')}
                    onFocus={() => setToTorn(false)}
                />
            </div>

            <div className="row">
                <div className="col-12 col-sm-8">
                    <InputMask
                        mask="99/9999"
                        maskChar=""
                        className="form-control"
                        placeholder='Data Vencimento'
                        value={`${cotacao.MesValidade}/${cotacao.AnoValidade}`}
                        onChange={({target}) => handelChangeCreditCard(target.value, 'validade')}
                        onFocus={() => setToTorn(false)}
                    />
                </div>

                <div className="col-12 col-sm-4">
                    <InputMask
                        mask="9999"
                        maskChar=""
                        className="form-control"
                        placeholder='CVV'
                        value={cotacao.CodigoSegurancaCartao}
                        onChange={({target}) => handelChangeCreditCard(target.value, 'CodigoSegurancaCartao')}
                        onFocus={() => setToTorn(true)}
                    />
                </div>
            </div>
        </div>
    );
}

export default PaymentCreditCard;