import { Modal } from "react-bootstrap";
import { formatMoney } from "../../utils/functions";

const ModalMoreInfo = ({ show, onHide, composicao = [] }) => {
    return (
        <Modal size="lg" show={show} onHide={onHide} centered className="modal-use-term">
            <Modal.Header closeButton>
                <Modal.Title>Mais informações</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th style={{ textAlign: 'right' }}>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(composicao && composicao.length > 0) && composicao.map(item => (
                            <tr hidden={!item.Valor}>
                                <td>{item.Nome}</td>
                                <td style={{ textAlign: 'right' }}>{formatMoney(item.Valor)}</td>
                            </tr>
                        ))}

                        {(composicao && composicao.length === 0) &&
                            <tr className="table-warning">
                                <td colSpan={2}>Nenhum registro</td>
                            </tr>
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Total</td>
                            <td style={{ textAlign: 'right' }}>
                                {formatMoney(composicao.reduce(function (initial, item) {
                                    return initial += item.Valor;
                                }, 0))}
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <div className="row justify-content-center">
                    <div className="col-12 col-sm-6">
                        <div className="d-grid">
                            <button className="btn btn-primary btn-rounded" onClick={onHide}>
                                Fechar
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalMoreInfo;